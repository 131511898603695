import React, { useState, useEffect } from 'react';
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import findLinksPlugin from "./findLinksPlugin";
import { toolbarPlugin } from '@react-pdf-viewer/toolbar';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import type { ToolbarSlot } from '@react-pdf-viewer/toolbar';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const Paper = ({ documentUrl, dlEnabled }) => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [scale, setScale] = useState(null);
    const [initialDistance, setInitialDistance] = useState(null);

    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            const later = () => {
                clearTimeout(timeout);
                func(...args);
            };
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        };
    };

    const updateHeight = () => {
        setWindowHeight(window.innerHeight);
    };

    const debouncedUpdateHeight = debounce(updateHeight, 200);

    useEffect(() => {
        window.addEventListener('resize', debouncedUpdateHeight);
        updateHeight();

        return () => {
            window.removeEventListener('resize', debouncedUpdateHeight);
        };
    }, [debouncedUpdateHeight]);

    const findLinksPluginInstance = findLinksPlugin();
    const zoomPluginInstance = zoomPlugin();
    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
    const isDlEnabled = (dlEnabled === 'true');

    const renderToolbar = (Toolbar) => (
        <Toolbar>
            {(slots) => {
                const {
                    CurrentPageInput,
                    Download,
                    EnterFullScreen,
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    Print,
                    ShowSearchPopover,
                    Zoom,
                    ZoomIn,
                    ZoomOut,
                    SwitchTheme,
                } = slots;
                return (
                    <div style={{ alignItems: 'center', display: 'flex', width: '100%' }}>
                        <div className="rpv-toolbar__left">
                            <div style={{ padding: '0px 2px' }}>
                                <ShowSearchPopover />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <GoToPreviousPage />
                            </div>
                            <div style={{ padding: '0px 2px', width: '4rem' }}>
                                <CurrentPageInput />
                            </div>
                            <div style={{ padding: '0px 2px', fontSize: "14px", marginLeft: "0.5rem" }}>
                                <NumberOfPages />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <GoToNextPage />
                            </div>
                        </div>
                        <div className="rpv-toolbar__center">
                            <div style={{ padding: '0px 2px' }}>
                                <ZoomOut />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <Zoom />
                            </div>
                            <div style={{ padding: '0px 2px' }}>
                                <ZoomIn />
                            </div>
                        </div>
                        <div className="rpv-toolbar__right">
                            {isDlEnabled &&
                                <div style={{ padding: '0px 2px' }}>
                                    <Download />
                                </div>
                            }
                            {isDlEnabled &&
                                <div style={{ padding: '0px 2px' }}>
                                    <Print />
                                </div>
                            }
                            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                                <EnterFullScreen />
                            </div>
                            <div style={{ padding: '0px 2px', marginLeft: 'auto' }}>
                                <SwitchTheme />
                            </div>
                        </div>
                    </div>
                );
            }}
        </Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
        sidebarTabs: (defaultTabs) => [],
    });

    const handlePinchStart = (event) => {
        if (event.touches.length === 2) {
            const distance = getDistance(event.touches[0], event.touches[1]);
            setInitialDistance(distance);
            event.preventDefault(); // Prevent the default pinch-to-zoom behavior
        }
    };

    const handlePinchMove = (event) => {
        if (event.touches.length === 2 && initialDistance !== null) {
            const distance = getDistance(event.touches[0], event.touches[1]);
            const newScale = (distance / initialDistance) * scale;
            setScale(newScale);
            zoomPluginInstance.zoomTo(newScale);
            event.preventDefault(); // Prevent the default pinch-to-zoom behavior
        }
    };

    const getDistance = (touch1, touch2) => {
        const dx = touch2.clientX - touch1.clientX;
        const dy = touch2.clientY - touch1.clientY;
        return Math.sqrt(dx * dx + dy * dy);
    };

    const handleDocumentLoad = ({ doc }) => {
        const viewerContainer = document.querySelector('.rpv-core__viewer');
        const viewerWidth = viewerContainer.clientWidth;
        doc.getPage(1).then((page) => {
            const viewport = page.getViewport({ scale: 1 });
            const initialScale = viewerWidth / viewport.width;
            setScale(initialScale);
            zoomPluginInstance.zoomTo(initialScale);
        });
    };

    useEffect(() => {
        const content = document.getElementById('content');
        content.addEventListener('touchstart', handlePinchStart, { passive: false });
        content.addEventListener('touchmove', handlePinchMove, { passive: false });

        return () => {
            content.removeEventListener('touchstart', handlePinchStart);
            content.removeEventListener('touchmove', handlePinchMove);
        };
    }, [scale, initialDistance]);

    return (
        <Worker workerUrl="https://pdfdeck.com/static/website/js/pdfworker.js">
            <div
                style={{
                    position: 'relative',
                    top: '0',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    border: "none",
                    height: `${windowHeight}px`,
                    width: "100%",
                }}
            >
                <div id="content" style={{ position: "relative", height: "100%", width: "100%" }}>
                    <Viewer
                        fileUrl={documentUrl}
                        plugins={[findLinksPluginInstance, defaultLayoutPluginInstance, zoomPluginInstance]}
                        defaultScale={SpecialZoomLevel.PageFit}
                        onDocumentLoad={handleDocumentLoad} // Update the scale when the document loads
                    />
                </div>
            </div>
        </Worker>
    );
};

export default Paper;
