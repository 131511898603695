import * as React from 'react';
import Paper from './components/Paper';
import Flipbook from './components/Flipbook';
import Presentation from './components/Presentation';


const App = () => {
    const documentUrl = document.getElementById('root').dataset.document;
    const documentType = document.getElementById('root').dataset.type;
    const dlEnabled = document.getElementById('root').dataset.dl;
    const showBranding = document.getElementById('root').dataset.branding;
    const brandingLink = document.getElementById('root').dataset.brandinglink;

    return (
        <>
            {documentType === 'flipbook' ? (
                <Flipbook documentUrl={documentUrl} dlEnabled={dlEnabled} showBranding={showBranding} brandingLink={brandingLink} />
            ) : documentType === 'presentation' ? (
                <Presentation documentUrl={documentUrl} dlEnabled={dlEnabled} showBranding={showBranding} brandingLink={brandingLink} />
            ) : (
                <Paper documentUrl={documentUrl} dlEnabled={dlEnabled} showBranding={showBranding} />
            )}
        </>
    );
};

export default App;
