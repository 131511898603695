import { LayerRenderStatus } from "@react-pdf-viewer/core";
import linkifyElement from "linkify-element";

const findLinksPlugin = () => {
  const findLinks = (e) => {
    if (e.status !== LayerRenderStatus.DidRender) {
      return;
    }

    e.ele.querySelectorAll(".rpv-core__text-layer-text").forEach((textEle) => {
      linkifyElement(textEle, {
        attributes: {
          style: "color: transparent; text-decoration: none;",
          target: "_blank"
        }
      });
    });
  };

  const findLinksAnnotations = (e) => {
    e.container
      .querySelectorAll(".rpv-core__annotation--link a")
      .forEach((link) => {
        link.setAttribute("target", "_blank");
      });
  };

  return {
    onTextLayerRender: findLinks,
    onAnnotationLayerRender: findLinksAnnotations
  };
};

export default findLinksPlugin;
