import * as React from 'react';
import { useState, useEffect, useRef } from 'react';

import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';

import styled from 'styled-components';
import 'react-pdf/dist/Page/AnnotationLayer.css';

const BookWrapper = styled.div`
  position: relative;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  perspective: 3000px;
`;

const FlipPage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  transition: transform 0.3s ease-in-out;
  transform: ${({ currentPage, index }) => ((currentPage > index) ? 'translate(-100%, 0)' : 'translate(0, 0)')};
`;


pdfjs.GlobalWorkerOptions.workerSrc = "https://pdfdeck.com/static/website/js/pdfworker.js";

const Flipbook = ({ documentUrl, dlEnabled, showBranding, brandingLink }) => {
    const [numPages, setNumPages] = useState(null);
    const [pages, setPages] = useState([]);
    const [width, setWidth] = useState(500); // Initialize width with default value
    const [height, setHeight] = useState(312);
    const [aspect, setAspect] = useState(0.70921985815);
    const [loading, setLoading] = useState(true);
    const [maxHeight, setMaxHeight] = useState(846.000000007);
    const [maxWidth, setMaxWidth] = useState(800);
    const isDlEnabled = (dlEnabled === 'true');
    const doShowBranding = (showBranding === 'true');

    const [currentPage, setCurrentPage] = useState(1);
    const paddingAmmount = 70;
    const [startX, setStartX] = useState(null);
    const currentPageRef = useRef(currentPage);
    const numPagesRef = useRef(numPages);

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
    };

    const handleTouchEnd = (e) => {
        if (!startX) return;

        const endX = e.changedTouches[0].clientX;
        const diffX = endX - startX;
        const threshold = 100; // Adjust this threshold as needed

        if (Math.abs(diffX) > threshold) {
            if (diffX > 0) {
                // Swipe right
                if(currentPage > 1){
                    prev(); // Call your nextPage function here
                };
            } else {
                if(numPages > currentPage){
                    nextPage(); // Call your prev function here
                }
            }
        }

        setStartX(null);
    };

    const handleKeyDown = (event) => {
        // Check if the pressed key is the left arrow key ('ArrowLeft') or the right arrow key ('ArrowRight')
        if (event.key === 'ArrowLeft' && currentPageRef.current > 1) {
            specialPrev(currentPageRef.current); // Call your nextPage function here
        } else if (event.key === 'ArrowRight' && numPagesRef.current > currentPageRef.current) {
            specialNext(currentPageRef.current); // Call your prev function here
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const debounce = (func, delay) => {
        let timeoutId;
        return function (...args) {
          const context = this;
          clearTimeout(timeoutId);
          timeoutId = setTimeout(() => {
            func.apply(context, args);
          }, delay);
        };
      };

    function handleResize() {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        
        // Calculate the maximum width based on window width and aspect ratio
        let maxWidthToSet = Math.min(windowWidth - 40, 1200);
        
        // Calculate the maximum height based on window height and aspect ratio
        let maxHeightToSet = Math.min(windowHeight - 70, maxWidthToSet / aspect);
        
        // Ensure that the width respects the aspect ratio
        maxWidthToSet = Math.min(maxWidthToSet, maxHeightToSet * aspect);
        
        // Set the maximum width and height
        setMaxWidth(maxWidthToSet);
        setMaxHeight(maxHeightToSet);
    }

    const debouncedResize = debounce(handleResize, 50);

    useEffect(() => {
        // Update ref values whenever currentPage or numPages change
        currentPageRef.current = currentPage;
        numPagesRef.current = numPages;
    }, [currentPage, numPages]);
    
    
    useEffect(() => {
        handleResize();
        window.addEventListener('resize', debouncedResize);
        window.addEventListener('orientationchange', debouncedResize);
        window.addEventListener('gesturechange', debouncedResize);
        return () => {
            window.removeEventListener('resize', debouncedResize);
            window.removeEventListener('orientationchange', debouncedResize)
            window.removeEventListener('gesturechange', debouncedResize)
        };
    }, [aspect, loading]);

    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    };

    const goToPage = (pageNum) => {
        setCurrentPage(pageNum);
    };

    const prev = () => {
        setCurrentPage(currentPage - 1);
    };

    const specialNext = (curr) => {
        setCurrentPage(curr + 1);
    };

    const specialPrev = (curr) => {
        setCurrentPage(curr - 1);
    };


    const onDocumentLoadSuccess = async (pdf) => {
        // If you want to get the dimension for page 1 (1-indexed)
        const page = await pdf.getPage(1);
        setWidth(page.view[2]); // Update width state
        setHeight(page.view[3]);
        const aspectToSet = page.view[2] / page.view[3];
        setAspect(aspectToSet);
        setNumPages(pdf.numPages);
        setMaxHeight(maxWidth / aspectToSet);
        var pagesToSet = [];
        for(var i=1; i<=pdf.numPages; i++){
            pagesToSet.push(<div style={{background: "white"}}>
                {/* <Page style={{background: "white"}} width={maxWidth} height={maxHeight} pageNumber={pdf.numPages - (i - 1)} renderTextLayer={false} /> */}
            </div>);
        }
        setPages(pagesToSet)
    //   return pages.reverse();
        // setPages(pagesToSet)
        setLoading(false);
    };

    // function loadPages(){
    //   
    // }

    function onItemClick({ pageNumber: itemPageNumber }){
        goToPage(itemPageNumber);
    };

    return (
        <div handleKeyDown={handleKeyDown}>
            <div style={{width: maxWidth + "px", margin: "auto", display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh'}}>
                <div style={{width: maxWidth + paddingAmmount, height: maxHeight + paddingAmmount, overflow: "hidden", display: 'flex', justifyContent: 'center', alignItems: 'center', position: "relative"}}>
                    <div style={{transform: "translate(0, -10px)", boxShadow: "0px 0px 4px rgba(0,0,0,0.08)"}}>
                        <Document
                        file={documentUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onItemClick={onItemClick}
                        externalLinkTarget="_blank"
                        >
                            {!loading && 
                            <>
                            <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
                                <BookWrapper width={maxWidth} height={maxHeight}>
                                {pages.map((page, index) => (
                                    <>
                                    {Math.abs(currentPage - (pages.length - index)) <= 2 &&
                                    <FlipPage key={index} currentPage={currentPage} index={pages.length - index}>
                                        <Page style={{background: "white"}} width={maxWidth} height={maxHeight} pageNumber={numPages - index} renderTextLayer={false} />
                                    </FlipPage>
                                    }
                                    </>
                                ))}
                                </BookWrapper>
                            </div>
                            </>
                            }
                        </Document>
                    </div>
                        {(currentPage && numPages) && 
                        <div style={{position: "absolute", bottom: "10px", width: maxWidth, display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                            {numPages > 1 && 
                                <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                                    <button style={{cursor: "pointer", background: "rgba(0,0,0,0.6)", border: "none", transformStyle: "preserve-3d",  padding: "5px 10px", borderRadius: "4px", opacity: currentPage > 1 ? 1 : 0.5}} title="Previous page" onClick={currentPage > 1 ? prev : null}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="white" style={{height: "10px", width: "10px"}}>
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                        </svg>

                                    </button>
                                    {(currentPage && numPages) && <div style={{color: "#222", fontSize: "14px", fontWeight: 600, margin: "0 8px", fontVariantNumeric: "tabular-nums"}}>{currentPage} / {numPages}</div>}
                                    
                                    <button title="Next page" style={{cursor: "pointer", background: "rgba(0,0,0,0.6)", border: "none", transformStyle: "preserve-3d", padding: "5px 10px", borderRadius: "4px", opacity: currentPage < numPages ? 1 : 0.5}} onClick={nextPage} onClick={currentPage < numPages ? nextPage : null}>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="3" stroke="white" style={{height: "10px", width: "10px"}}>
                                        <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </button>
                                    
                                </div>
                            }
                            <div style={{alignItems: 'center', display: "flex"}}>
                                {isDlEnabled && <a href={documentUrl} download="presentation.pdf" style={{marginRight: "8px", color: "#222", marginTop: "3px"}}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" style={{color: "#222", height: "1.3rem", width: "1.3rem"}}>
                                  <path stroke-linecap="round" stroke-linejoin="round" d="m9 13.5 3 3m0 0 3-3m-3 3v-6m1.06-4.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                                </svg>
                                </a>}
                                {doShowBranding && <a href={brandingLink} target="_blank" rel="nofollow" style={{marginLeft: "10px", "textDecoration": "underline", fontSize: "12px", color: "#222"}}>
                                    Shared with PDF Deck
                                </a>}
                            </div>
                            
                        </div>
                      }
                </div>
            </div>
        </div>     
    );
};

export default Flipbook;
